<template>
  <div>
    <PageHeader page-name="События">
      <template>
        <router-link :to="{name: 'CreateEventsMain'}">
          <el-button type="primary">
            Создать События
          </el-button>
        </router-link>
      </template>
    </PageHeader>
    <MainPanel>
      <List v-loading="loading" />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import List from '@/components/events-main/List'
import MainPanel from '@/components/common/MainPanel'
import PageHeader from '@/components/common/PageHeader';

export default {
  name: 'ListPage',
  components: {MainPanel, List, PageHeader},
  metaInfo: {
    title: 'EventsMain',
  },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.fetchEventsList()
    this.loading = false
  },
  methods: {
    ...mapActions('eventsMain', {
      fetchEventsList: 'fetchEventsList',
    }),
  },
}
</script>
