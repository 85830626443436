<template>
  <el-form
    ref="form"
    class="filter-form"
    label-position="top"
    :model="filters"
  >
    <el-form-item label="По статусу активности">
      <el-select
        v-model="filters.state"
        class="multiselect"
        size="small"
        @change="doFilter"
      >
        <el-option
          v-for="state in [{key: null, label: 'Любой'},...productStatesTransformed]"
          :key="state.key"
          :label="state.label"
          :value="state.key"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="По админ статусу">
      <el-select
        v-model="filters.admin_status"
        class="multiselect"
        placeholder="Выберите админ статус"
        size="small"
        @change="doFilter"
      >
        <el-option
          v-for="admin_status in adminStatuses"
          :key="admin_status.key"
          :label="admin_status.label"
          :value="admin_status.label"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="По топ статусу">
      <el-select
        v-model="filters.is_top"
        class="multiselect"
        size="small"
        @change="doFilter"
      >
        <el-option
          v-for="state in [{key: null, label: 'Любой'}, {key: true, label: 'Топ'}, {key: false, label: 'Не топ'}]"
          :key="state.key"
          :label="state.label"
          :value="state.key"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="По региону">
      <el-select
        v-model="filters.region_id"
        class="multiselect"
        size="small"
        @change="doFilter"
      >
        <el-option
          v-for="state in [{key: null, label: 'Любой'},...cityOptions]"
          :key="state.key"
          :label="state.label"
          :value="state.key"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="По категории">
      <el-select
        v-model="filters.category_id"
        no-data-text="Ничего не найдено"
        placeholder="Виберите категорию"
        size="small"
        @change="doFilter"
      >
        <el-option
          v-for="category in [{id: null, name: 'Любая'}]"
          :key="category.id"
          :label="category.name"
          :value="category.id"
        />
        <el-option-group
          v-for="group in [{id: null, name: null}, ...categories]"
          :key="group.id"
          class="el-select__shadow-group"
          :label="group.name"
          :value="group.id"
        >
          <template>
            <el-option
              v-if="group.id"
              :key="group.id"
              class="el-select__shadow-group-item"
              :label="group.name"
              :value="group.id"
            />
          </template>
          <el-option
            v-for="category in group.children"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          />
        </el-option-group>
      </el-select>
    </el-form-item>


    <el-form-item label="По названию та описанию">
      <el-input
        v-model="filters.search"
        v-debounce="doFilter"
        :disabled="Boolean(filters.search_username.length) || Boolean(filters.search_phone.length)"
        placeholder="Поиск"
        prefix-icon="el-icon-search"
        size="small"
      />
    </el-form-item>

    <el-form-item label="По номеру телефона">
      <el-input
        v-model="filters.search_phone"
        v-debounce="doFilter"
        :disabled="Boolean(filters.search_username.length) || Boolean(filters.search.length)"
        placeholder="Поиск"
        prefix-icon="el-icon-search"
        size="small"
      />
    </el-form-item>

    <el-form-item label="По имени пользователя">
      <el-input
        v-model="filters.search_username"
        v-debounce="doFilter"
        :disabled="Boolean(filters.search_phone.length) || Boolean(filters.search.length)"
        placeholder="Поиск"
        prefix-icon="el-icon-search"
        size="small"
      />
    </el-form-item>

    <el-button
      v-if="isFiltersChanged"
      size="small"
      type="gray"
      @click="clearFilters"
    >
      Сбросить фильтры
    </el-button>
  </el-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

const defaultFilterValues = {
  search: '',
  search_phone: '',
  search_username: '',
  state: null,
  admin_status: 'Любой',
  category_id: null,
  region_id: null,
  is_top: null,
}

export default {
  name: 'Filters',
  data() {
    return {
      filters: {...defaultFilterValues},
      originFilters: {...defaultFilterValues},
      activeField: null,
      categories: [],
    }
  },

  computed: {
    //...mapState('wishes/categories', ['categories']),
    ...mapGetters('products', ['productStatesTransformed', 'productAdminStatusesTransformed']),
    ...mapState('regions', ['regions']),

    isFiltersChanged() {
      return JSON.stringify(this.originFilters) !== JSON.stringify(this.filters)
    },
    //categoryOptions() {
    //  return this.categories.map(category => ({
    //    key: category.id,
    //    label: category.name,
    //  }))
    //},
    cityOptions() {
      return this.regions.map(region => ({
        key: region.id,
        label: region.name,
      }))
    },
    adminStatuses() {
      return this.productAdminStatusesTransformed.map(status => ({
        key: status.key,
        label: status.key === 'EMPTY_STATUS' ? 'Любой' : status.label,
      }))
    },
  },

  async mounted () {
    this.fetchRegions()
    this.categories = await this.fetchContractorCategories();
  },

  methods: {
    //...mapActions('wishes/categories', ['fetchCategories']),
    ...mapActions('regions', ['fetchRegions']),
    ...mapActions('contractors', ['fetchContractorCategories']),

    async doFilter() {
      this.$emit('filter', this.filters)
    },
    async clearFilters() {
      this.filters = {...defaultFilterValues}
      this.$emit('filter', this.filters)
      this.activeField = null;
    },
  },
}
</script>

<style lang="sass" scoped>

.multiselect
  min-width: 250px

</style>
