import api from '@/utils/api'

export default {
  state: () => ({
    offers: null,
    reasons: [],
    offersStates: [],
  }),
  mutations: {
    setOffers(state, payload) {
      state.offers = payload
    },
    setReasons(state, payload) {
      state.reasons = payload
    },
    setOffersStates(state, payload) {
      state.offersStates = payload
    },
  },
  getters: {
    // Нужно ли это оставить?
    // getOfferStateByKey: state => {
    //   return key => {
    //     return state.offersStates.find(x => x.value === key).title
    //   }
    // },
    offersStatesTransformed: state => {
      return state.offersStates.map(item => {
        return {
          key: item,
          label: item,
        }
      })
    },
  },
  actions: {
    async fetchOffers({commit}, params) {
      const copy = {...params}
      if (!copy.search) delete copy.search
      if (!copy.state) delete copy.state
      const res = await api.http.get('admin/offers', {params: copy})
      commit('setOffers', res?.data)
    },

    async fetchOffersForWishes(_, wishId) {
      const res = await api.http.get(`/admin/wishes/${wishId}/offers`);
      return res?.data;
    },

    async fetchOffer(_, id) {
      const res = await api.http.get(`admin/offers/${id}`)
      return res?.data?.data
    },

    async approveOffer(_, id) {
      await api.http.post(`admin/offers/${id}/approve`)
    },

    async discardOffer(_, {id, form}) {
      await api.http.post(`admin/offers/${id}/discard`, form)
    },

    async fetchReasons({commit}, params) {
      const res = await api.http.get('admin/offers/reasons', {params})
      commit('setReasons', res?.data?.data)
    },

    async fetchOffersStates({commit}) {
      const res = await api.http.get('admin/offers/states')
      commit('setOffersStates', res?.data)
    },

    async fetchOffersByUserSlug(_, userSlug) {
      const res = await api.http.get(`/admin/offers/${userSlug}/user-offers-by-slug`)
      return res?.data.data
    },


    async takeMutex(_, id) {
      await api.http.post(`admin/offers/${id}/mutex`)
    },

    async leaveMutex(_, id) {
      await api.http.delete(`admin/offers/${id}/mutex`)
    },

    async updateOffer(_, {id, form}) {
      const formClone = {...form}
      formClone.images = formClone.images.map(x => x.id)
      await api.http.post(`admin/offers/${id}`, formClone)

    },

    async deleteOffer(_, id) {
      return await api.http.delete(`admin/offers/${id}`)
    },
  },
  namespaced: true,
}
