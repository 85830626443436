import api from '@/utils/api';

export default {
  state: () => ({
    eventsList: null,
    eventDetails: null,
    categoriesList: null,
  }),

  mutations: {
    setEventsList(state, payload) {
      state.eventsList = payload;
    },
    setEventDetails(state, payload) {
      state.eventDetails = payload;
    },
    setCategories(state, payload) {
      state.categoriesList = payload;
    },
  },

  actions: {
    // Отримання списку подій
    async fetchEventsList({ commit }) {
      const res = await api.http.get('admin/search-events');
      commit('setEventsList', res?.data?.data);
    },
    async fetchCategories({ commit }) {
      // Новий екшен
      try {
        const res = await api.http.get('/tree-categories');
        commit('setCategories', res?.data?.data);
      } catch (error) {
        console.error('Помилка завантаження категорій:', error);
      }
    },

    // Створення нової події
    // async createEvent(_, form) {
    //   await api.http.post('admin/search-events', form);
    // },

    // // Редагування події
    // async updateEvent(_, { id, form }) {
    //   await api.http.post(`admin/search-events/${id}/update`, form);
    // },
    // Створення нової події
    async createEvent(_, form) {
      await api.http.post('admin/search-events', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
    },
    // Редагування події
    async updateEvent(_, { id, form }) {
      if (!id) {
        console.error('Невалідний ID');
        return;
      }

      await api.http.post(`admin/search-events/${id}/update`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    //Створення нової події
    // async createEvent(_, form) {
    //   const formData = new FormData();

    //   // Додаємо дані форми
    //   for (const key in form) {
    //     formData.append(key, form[key]);
    //   }

    //   // Відправляємо як FormData
    //   await api.http.post('admin/search-events', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   });
    // },

    // // Редагування події
    // async updateEvent(_, { id, form }) {
    //   const formData = new FormData();

    //   // Додаємо дані форми
    //   for (const key in form) {
    //     formData.append(key, form[key]);
    //   }

    //   await api.http.post(`admin/search-events/${id}/update`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   });
    // },

    // Отримання інформації про конкретну подію
    async getEventDetails({ commit }, id) {
      if (!id || id === 'undefined') {
        console.error('Невалідний id для події');
        return;
      }
      const res = await api.http.get(`admin/search-events/${id}`);
      commit('setEventDetails', res?.data?.data);
      return res?.data?.data;
    },

    // Видалення події
    async deleteEvent(_, id) {
      await api.http.delete(`admin/search-events/${id}/delete`);
    },
  },

  getters: {
    eventsList: state => state.eventsList,
    eventDetails: state => state.eventDetails,
    categoriesList: state => state.categoriesList,
  },

  namespaced: true,
};
