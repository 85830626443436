<template>
  <div class="panel panel-default filter-panel">
    <div class="filter-panel__left">
      <i class="fas fa-filter" />
    </div>
    <div class="filter-panel__right">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterPanel',
}
</script>

<style lang="sass" scoped>

.panel
  margin-bottom: 0

.filter-panel
  display: flex
  max-width: 1160px

.filter-form
  display: flex
  align-items: flex-end
  justify-content: flex-start
  flex-wrap: wrap
  gap: 10px 20px

  > *
    margin-right: 15px
    margin-top: 15px

  button
    margin-bottom: 22px


.filter-panel__left
  display: flex
  align-items: center
  justify-content: center
  font-size: 66px
  color: #f1f1f1
  padding-right: 4px
  padding-left: 15px

.filter-panel__right
  padding: 15px 15px 15px 15px

::v-deep
  .el-form-item
    margin: 0

  .el-button
    height: 32px
    padding-top: 5px
    padding-bottom: 5px
    width: 190px
    transform: translateY(-4px)
    align-self: flex-end


  .el-form-item__label
    font-size: 12px
    margin-bottom: 0

  .el-select.multiselect
    min-width: 130px

</style>
