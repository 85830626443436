<template>
  <div>
    <PageHeader link-name="Назад" link-path="ProductsList" page-name="Модерирование Обьявлений">
      <template>
        <div class="title-bar__button-wrapper">
          <el-button type="danger" @click="transferToContractor">
            Перетворити оголошення у виконавця
          </el-button>
          <router-link class="button-wrapper-link" :to="{name: 'ProductsList'}">
            <el-button type="gray">
              <i class="fas fa-list" /> Список
            </el-button>
          </router-link>

          <!--          <router-link class="button-wrapper-link" :to="{name: 'LogsList', params: {id: product && product.id, subject: 'product'}}">-->
          <!--            <el-button type="info">-->
          <!--              <i class="fas fa-info-circle" /> Действия над Обьявлением-->
          <!--            </el-button>-->
          <!--          </router-link>-->

          <el-button type="success" @click="onSaveProduct">
            Сохранить
          </el-button>
        </div>
      </template>
    </PageHeader>
    <MainPanel v-loading="loading" class="panel--mb">
      <table v-if="product" class="comparing-table">
        <tr>
          <th>
            <div class="comparing-columns-heading">
              Текущая версия
            </div>
          </th>
          <th v-if="product.prev_version">
            <div class="comparing-columns-heading">
              Предыдущая версия
            </div>
          </th>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Название
            </div>
            <EditableText v-model="form.name" />
          </td>
          <td v-if="product.prev_version">
            <div class="comparing-table__label">
              Название
            </div>
            {{ product.prev_version.data.name }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="comparing-table__label">
              Характеристики
            </div>
            <EditableText v-model="form.characteristic" />
          </td>
          <td v-if="product.prev_version">
            <div class="comparing-table__label">
              Характеристики
            </div>
            {{ product.prev_version.data.characteristic }}
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Описание
            </div>
            <EditableText v-model="form.description" rows="10" type="textarea" />
          </td>
          <td v-if="product.prev_version">
            <div class="comparing-table__label">
              Описание
            </div>
            {{ product.prev_version.data.description }}
          </td>
        </tr>

        <tr>
          <td v-if="product.prev_version">
            <div class="comparing-table__label">
              <strong>Топ статус</strong>
            </div>

            <el-switch
              active-color="#13ce66"
              :value="product.is_top"
              @change="changeTopStatus"
            />
          </td>
          <td>
            <div class="comparing-table__label">
              <strong>Топ статус</strong>
            </div>

            <el-switch
              v-if="product.prev_version"
              active-color="#13ce66"
              disabled
              :value="product.prev_version.data.is_top"
            />
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Основная Категория
            </div>

            <CategorySelector
              v-model="form.category"
            />
            <!--            <div class="comparing-table__label">-->
            <!--              Выбрать Дополнителную Категорию-->
            <!--            </div>-->
            <!--            <div-->
            <!--              v-if="product.additional_categories.length < 10"-->
            <!--              class="additional-categories"-->
            <!--            >-->
            <!--              <CategorySelector-->
            <!--                v-model="additionalCategory"-->
            <!--                class="additional-categories__block"-->
            <!--                :make-disable-categories="false"-->
            <!--                placeholder="Выберите дополнительную категорию"-->
            <!--              />-->
            <!--              <el-button @click="addAdditionalCategory">Добавить</el-button>-->
            <!--            </div>-->
            <br>
            <div
              v-for="(id, index) in form.additional_categories"
              :key="index"
              class="additional-categories__list"
            >
              <CategorySelector
                v-model="form.additional_categories[index]"
                :disabled="true"
                style="width: 100%"
              />
              <el-button
                icon="el-icon-delete"
                size="mini"
                type="danger"
                @click="toDeleteAdditionalCategory(id)"
              />
            </div>
          </td>

          <td v-if="product.prev_version">
            <div class="comparing-table__label">
              Категории
            </div>
            <div v-if="product.prev_version.category">
              {{ product.prev_version.category.name }}
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Изображения
            </div>
            <ImageViewer
              :images="form.images"
              removing-allowed
              @change-images="changeImages"
            />
          </td>
          <td v-if="product.prev_version">
            <div class="comparing-table__label">
              Изображения
            </div>
            <ImageViewer
              :images="product.prev_version.data.images"
              @change-images="changeImages"
            />
          </td>
        </tr>

        <tr>
          <td>
            <div class="comparing-table__label">
              Ссылка на видео
            </div>
            {{ product.video_link || '---' }}
          </td>
          <td v-if="product.prev_version && product.prev_version.data.video_link">
            <div class="comparing-table__label">
              Ссылка на видео
            </div>
            {{ product.prev_version.data.video_link || '---' }}
          </td>
        </tr>
      </table>
    </MainPanel>

    <MainPanel v-loading="loading">
      <div class="comparing-columns-heading" style="margin-bottom: 30px;">
        Пользователь
      </div>
      <table v-if="product" class="key-values-table">
        <tr v-if="product.frontend_url">
          <td>Ссылка на объявление</td>
          <td>
            <a :href="product.frontend_url" target="_blank">
              {{ product.frontend_url }}
            </a>
          </td>
        </tr>

        <tr v-else>
          <td>id</td>
          <td>{{ product.id }}</td>
        </tr>

        <tr>
          <td>Админ. статус</td>
          <td>
            <el-dropdown
              class="btn-item"
              trigger="click"
              @command="(command) => changeStatus(command)"
            >
              <span class="el-dropdown-link">
                {{ product.admin_status || 'Выберите статус' }}
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(value, key) in adminStatuses"
                  :key="key"
                  :command="value"
                >
                  {{ value }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </td>
        </tr>

        <tr v-if="product.created_at">
          <td>Дата создания</td>
          <td>{{ product.created_at }}</td>
        </tr>

        <tr v-if="product.price">
          <td>Цена</td>
          <td v-if="product.is_price_from">от {{ product.price }} {{ getCurrencyByKey(product.currency) }}</td>
          <td v-else>{{ product.price }} {{ getCurrencyByKey(product.currency) }}</td>
        </tr>

        <tr v-else>
          <td>Цена</td>
          <td>Договорная</td>
        </tr>

        <tr v-if="product.condition">
          <td>Состояние</td>
          <td>{{ getProductConditionByKey(product.condition) }}</td>
        </tr>

        <tr v-if="product.contacts.phone">
          <td>Номер телефона</td>
          <td>{{ product.contacts.phone }}</td>
        </tr>

        <tr v-if="product.city">
          <td>Населённый пункт</td>
          <td>{{ product.city.name }}</td>
        </tr>

        <tr v-if="product.city">
          <td>Область</td>
          <td>{{ product.city.region_name }}</td>
        </tr>

        <tr v-if="product.complaints_count">
          <td>Количество жалоб</td>
          <td>{{ product.complaints_count }}</td>
        </tr>

        <tr>
          <td>Пользователь</td>
          <td>
            <UserCard :user="product.user" />
          </td>
        </tr>

        <tr v-if="product.city">
          <td>Статус</td>
          <td>
            <el-tag
              class="type-tag"
              size="small"
              :type="getStatusTagType(product.state)"
            >
              {{ product.state.replace('_', ' ') }}
            </el-tag>
          </td>
        </tr>

        <tr>
          <td>Подтверждение</td>
          <td>
            <el-button-group>
              <el-button
                :disabled="!product.actions.discard"
                size="small"
                :type="product.state === 'DISCARDED' ? 'danger' : ''"
                @click="$refs.checkReasonModal.open()"
              >
                Нет
              </el-button>
              <el-button
                :disabled="!product.actions.approve"
                size="small"
                :type="product.state === 'CHECKED' ? 'success' : ''"
                @click="onApproveProduct"
              >
                Да
              </el-button>
            </el-button-group>
          </td>
        </tr>
        <tr>
          <td />
          <td><el-button @click="deleteThisProduct">Удалить</el-button></td>
        </tr>
      </table>
    </MainPanel>

    <CheckReasonModal ref="checkReasonModal" @check="onDiscardProduct" />
    <el-dialog
      :before-close="handleClose"
      class="text-center custom-dialog"
      title="Увага!"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div class="dialog-content">
        <p class="message">{{ contractor_message }}</p>

        <a class="link" :href="contractor_frontend_url" target="_blank">Профіль виконавця на сайті</a>

        <a class="link" :href="contractor_admin_url" target="_blank">Профіль виконавця в адмінці</a>

        <el-button
          v-if="contractor_change_content"
          class="replace-button"
          type="danger"
          @click="replaceContent"
        >
          Замінити контент
        </el-button>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions, mapGetters} from 'vuex'
import EditableText from '@/components/common/EditableText'
import ImageViewer from '@/components/common/ImageViewer'
import CheckReasonModal from '@/components/products/CheckReasonModal'
import CategorySelector from '@/components/wishes/CategorySelector'
import UserCard from '@/components/user-card/UserCard';
import PageHeader from '@/components/common/PageHeader';


export default {
  metaInfo: {
    title: 'Модерирование обьявлений',
  },
  components: {CategorySelector, CheckReasonModal, ImageViewer, EditableText, MainPanel, UserCard, PageHeader},
  data() {
    return {
      productId: null,
      product: null,
      loading: false,
      timer: null,
      form: {
        name: '',
        description: '',
        categories: [],
        additional_categories: [],
        category_id: '',
        characteristic: '',
      },
      contractor_frontend_url: '#',
      contractor_admin_url: '#',
      contractor_message: 'Контент виконавця був успішно замінений контентом оголошення. Оголошення видалено',
      contractor_change_content: false,
      additionalCategory:'',
      listOffersForWishes: [],
      dialogVisible: false,
      localAdminStatuses: {'DEFAULT': 'Без статусу'},
      adminStatusesLoaded: false,
    }
  },
  computed: {
    ...mapGetters('value-objects', [
      'getProductConditionByKey',
      'getStatusTagType',
      'getCurrencyByKey',
    ]),
    ...mapGetters('products', [
      'productAdminStatusesTransformed',
    ]),
    adminStatuses() {
      return this.localAdminStatuses.reduce((acc, status) => {
        acc[status.key] = status.label;
        return acc;
      }, {});
    },
  },
  async created() {
    this.productId = this.$route.params.id
    await this.loadAdminStatuses()
    try {
      try {
        await this.takeMutex(this.productId)
      } catch (e) {
        this.$alert('Это обьявление сейчас редактируется дргуим пользователем. Попробуйте позже');

        throw e;
      }

      await Promise.all([
        this.getProduct(),
        this.fetchProductConditions(),
        this.fetchCurrencies(),
      ])
      this.fillForm()
      this.setMutexLoop()
    } catch (e) {
      this.$router.back()
    }
    if(this.product.category) {
      this.form.category_id = await this.product.category.id
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    try {
      this.leaveMutex(this.productId)
    } catch (e) {

    }
  },
  methods: {
    ...mapActions('products', ['fetchProduct',
      'fetchProductAdminStatuses',
      'approveProduct',
      'discardProduct',
      'takeMutex',
      'leaveMutex',
      'updateProduct',
      'deleteProduct',
      'transferProductToContractor',
      'changeContractorContent',
      'saveAdminStatus',
      'changeProductTopStatus',
    ]),
    ...mapActions('offers', [
      'fetchOffersForWishes',
    ]),
    ...mapActions('value-objects', [
      'fetchProductConditions',
      'fetchCurrencies',
    ]),
    async loadAdminStatuses() {
      if (!this.adminStatusesLoaded) {
        await this.fetchProductAdminStatuses()
        this.localAdminStatuses = [
          { key: 'DEFAULT', label: 'Без статусу' },
          ...this.productAdminStatusesTransformed,
        ]
        this.adminStatusesLoaded = true
      }
    },
    async getProduct() {
      this.product = await this.fetchProduct(this.productId)

    },
    async onApproveProduct() {
      try {
        await this.approveProduct(this.product.id)
        await this.getProduct()
      } catch (e) {
        this.$message.error(e.response.data.message)
      }
    },
    async onDiscardProduct(form) {
      await this.discardProduct({
        id: this.product.id,
        form,
      })
      await this.getProduct()
    },
    async onSaveProduct() {
      try {
        await this.updateProduct({
          id: this.product.id,
          form: this.form,
        })
        await this.$router.go(0)
        this.$message.success('Обьявление успешно сохранено')
      } catch (e) {
        this.$message.error(e.response.data.message)
      }
    },
    fillForm() {
      this.form.name = this.product.name
      this.form.description = this.product.description
      this.form.characteristic = this.product.characteristic
      this.form.images = this.product.images
      if(this.product.category) {
        this.form.category_id = this.product.category.id
      }
      if(this.product.categories_path.length === 0) return
      this.form.category = this.product.categories_path[this.product.categories_path.length - 1].id
    },
    changeImages(res) {
      const clone = {...this.form}
      clone.images = res
      this.form = clone
    },
    setMutexLoop() {
      this.timer = setInterval(() => {
        this.takeMutex(this.product.id)
      }, 60000)
    },
    async deleteThisProduct()
    {
      let confirmation = false
      try {
        confirmation = await this.$confirm('Вы дейсвтительно хотите удалить обьявление? Будут удалены также все предложения.', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
          type: 'warning',
          center: true,
        })
      } catch (e) {
      }

      if (!confirmation) {
        return
      }

      try {
        await this.deleteProduct(this.product.id)
        this.$message.success('Обьявление успешно удалено')
        await this.$router.push({name: 'WishesList'})
      } catch (e) {
        this.$message.error('Не получилось удалить обьявление.')
      }
    },
    addAdditionalCategory(){
      if(!this.additionalCategory) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Нужно выбрать Категорию',
        });
        return
      }
      this.form.additional_categories.push(this.additionalCategory[this.additionalCategory.length - 1])
      this.additionalCategory = ''
    },

    toDeleteAdditionalCategory(id){
      this.form.additional_categories.splice(id, 1)
    },

    async transferToContractor(){
      if(!this.product.category) {
        this.$message.error('Нужно выбрать Категорию');
        return
      }
      let confirmation = false
      try {
        confirmation = await this.$confirm('Ви впевнені, що хочете створити сторінку виконавця на основі оголошення у профілі автора оголошення? Після створення сторінки виконавця, оголошення буде видалено автоматично!', {
          cancelButtonText: 'Ні',
          confirmButtonText: 'Так',
          type: 'warning',
          center: true,
        })
      } catch (e) {
      }
      if (!confirmation) {
        return
      }
      try {
        let result = await this.transferProductToContractor(this.product.id)
        if (result.status === 'ok') {
          this.contractor_admin_url = result.admin_url;
          this.contractor_frontend_url = result.frontend_url;
          this.contractor_message = result.message;
          this.contractor_change_content = result.contractor_change_content;
          this.dialogVisible = true;
        } else {
          this.$message.error(result.message);
        }
      } catch (e) {
        console.log(e)
        this.$message.error('Не вдалося перетворити оголошення у виконавця')
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.$router.push({name: 'ProductsList'});
    },
    async replaceContent() {
      let confirmation = false
      try {
        confirmation = await this.$confirm('Ви впевнені, що хочете замінити контент існуючого користувача на контент оголошення?', {
          cancelButtonText: 'Ні',
          confirmButtonText: 'Так',
          type: 'warning',
          center: true,
        })
      } catch (e) {
      }
      if (!confirmation) {
        return
      }
      try {
        let result = await this.changeContractorContent(this.product.id)
        if (result.status === 'ok') {
          this.contractor_admin_url = result.admin_url;
          this.contractor_frontend_url = result.frontend_url;
          this.contractor_message = result.message;
          this.contractor_change_content = result.contractor_change_content;
          this.dialogVisible = true;
        } else {
          this.$message.error(result.message);
        }
      } catch (e) {
        console.log(e)
        this.$message.error('Не вдалося перетворити оголошення у виконавця')
      }
    },
    changeStatus(value) {
      if (value === 'Без статусу') {
        value = null;
      }
      this.saveAdminStatus({id: this.product.id, status: value})
        .then(() => {
          this.product.admin_status = value;
          this.$message({
            message: 'Статус успешно обновлен',
            type: 'success',
          })
        })
        .catch(error => {
          console.error('Ошибка при обновлении статуса:', error)
          this.$message({
            message: 'Ошибка при обновлении статуса',
            type: 'error',
          })
        })
    },
    async changeTopStatus(val) {
      this.product.is_top = val

      try {
        await this.changeProductTopStatus({id: this.product.id, params: {is_top: val}})
        await this.$message.success('Статус успешно изменен');
      } catch (e) {
        await this.$message.error('Статус изменить не удалось');
        this.product.is_top = !val
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.wish-info-label
  margin-bottom: 10px
  font-weight: bold

.wish-info-wrapper
  display: flex

  > *
    margin-right: 50px

.panel--mb
  margin-bottom: 25px

.comparing-table__label
  margin-bottom: 4px
  font-weight: 600

.additional-categories
  display: flex
  align-items: center

.additional-categories__block
  width: 100%
  margin-right: 10px

.additional-categories__list
  display: flex
  padding: 10px
  gap: 10px
  width: 70vw

.custom-dialog
  text-align: center

.dialog-content
  display: flex
  flex-direction: column
  align-items: center
  gap: 15px

.message
  font-size: 18px
  line-height: 1.4
  max-width: 100%
  margin: 0
  word-break: keep-all
  overflow-wrap: break-word
  hyphens: auto

.link
  display: block
  margin-top: 10px

.replace-button
  margin-top: 10px

</style>
