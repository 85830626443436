<template>
  <div>
    <div v-if="form" class="internal-form">
      <el-form
        ref="form"
        label-position="top"
        label-width="220px"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="Слаг" prop="slug">
          <el-input v-model="form.slug" />
          <div v-if="form.slug" class="slug-preview">URL страницы: {{ generateSlugPreview }}</div>
          {{ $_ErrorMixin_getError('slug') }}
        </el-form-item>

        <el-form-item label="Заголовок страницы UA" prop="title">
          <el-input v-model="form.title" />
          {{ $_ErrorMixin_getError('title') }}
        </el-form-item>
        <el-form-item label="Заголовок страницы RU" prop="title_ru">
          <el-input v-model="form.title_ru" />
          {{ $_ErrorMixin_getError('title_ru') }}
        </el-form-item>

        <el-form-item label="Описание страницы UA" prop="description">
          <el-input v-model="form.description" type="textarea" />
          {{ $_ErrorMixin_getError('description') }}
        </el-form-item>
        <el-form-item label="Описание страницы RU" prop="description_ru">
          <el-input v-model="form.description_ru" type="textarea" />
          {{ $_ErrorMixin_getError('description_ru') }}
        </el-form-item>

        <div class="inline-form-container">
          <el-form-item label="Заглавное изображение страницы" prop="imagesIds">
            <div class="internal-form__upload-image">
              <el-upload
                ref="upload"
                action="#"
                class="upload-demo"
                drag
                :http-request="onFileSelect"
              >
                <i class="el-icon-upload" />
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              </el-upload>
            </div>
            <div v-if="imageError">
              {{ $_ErrorMixin_getError('file') }}
              {{ $_ErrorMixin_getError('imagesIds') }}
              {{ $_ErrorMixin_getError('imagesIds.*') }}
              {{ $_ErrorMixin_getError('imagesIds.0') }}
            </div>
          </el-form-item>

          <el-form-item
            v-if="selectedImage"
            class="uploaded-image-container"
            label="Изображение"
            prop="image_links"
            width="100"
          >
            <img alt="Изображение" class="uploaded-image" :src="selectedImage.path">
          </el-form-item>
        </div>
        <el-form-item label="Название CTA кнопки UA" prop="button_name">
          <el-input v-model="form.button_name" />
          {{ $_ErrorMixin_getError('button_name') }}
        </el-form-item>
        <el-form-item label="Название CTA кнопки RU" prop="button_name_ru">
          <el-input v-model="form.button_name_ru" />
          {{ $_ErrorMixin_getError('button_name_ru') }}
        </el-form-item>

        <el-form-item label="Ccылка для CTA кнопки UA" prop="button_link">
          <el-input v-model="form.button_link" />
          {{ $_ErrorMixin_getError('button_link') }}
        </el-form-item>
        <el-form-item label="Ссылка для CTA кнопки RU" prop="button_link_ru">
          <el-input v-model="form.button_link_ru" />
          {{ $_ErrorMixin_getError('button_link_ru') }}
        </el-form-item>

        <el-form-item label="Выбор отображающихся категорий" prop="categoriesIds">
          <div v-if="id" class="slug-preview">
            Чтобы изменить порядок категорий, нужно удалить все категории, кроме первой и сохранить страницу. После этого, опять зайти в редактирование, и добавить категории в нужном порядке.
          </div>
          <div
            class="additional-categories"
          >
            <CategorySelector
              v-model="selectedCategory"
              class="additional-categories__block"
              :make-disable-categories="true"
              placeholder="Категория"
            />
            <el-button
              style="margin: 5px;"
              @click="addCategory"
            >
              Добавить
            </el-button>
          </div>
          <div
            v-for="(category, index) in form.categoriesIds"
            :key="index"
            class="additional-categories__list"
          >
            <CategorySelector
              v-model="form.categoriesIds[index]"
              :make-disable-categories="true"
              style="width: 100%"
            />
            <el-button
              icon="el-icon-delete"
              size="mini"
              type="danger"
              @click="deleteCategory(category)"
            />
          </div>
        </el-form-item>

        <el-form-item label="Город" prop="city_id">
          <div class="additional-categories__list">
            <el-select
              v-if="cityOptions && cityOptions.length > 0"
              v-model="selectedCityId"
              filterable
              placeholder="Введите город"
              remote
              :remote-method="getCity"
              reserve-keyword
              @change="selectedCityChange"
            >
              <el-option
                v-for="item in cityOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <el-button
              v-if="selectedCityId"
              icon="el-icon-delete"
              size="mini"
              type="danger"
              @click="deleteCity()"
            />
          </div>
          {{ $_ErrorMixin_getError('city_id') }}
        </el-form-item>

        <el-form-item label="SEO Title UA" prop="seo_title">
          <el-input v-model="form.seo_title" />
          {{ $_ErrorMixin_getError('seo_title') }}
        </el-form-item>
        <el-form-item label="SEO Title RU" prop="seo_title_ru">
          <el-input v-model="form.seo_title_ru" />
          {{ $_ErrorMixin_getError('seo_title_ru') }}
        </el-form-item>

        <el-form-item label="SEO Description UA" prop="seo_description">
          <el-input v-model="form.seo_description" />
          {{ $_ErrorMixin_getError('seo_description') }}
        </el-form-item>
        <el-form-item label="SEO Description RU" prop="seo_description_ru">
          <el-input v-model="form.seo_description_ru" />
          {{ $_ErrorMixin_getError('seo_description_ru') }}
        </el-form-item>

        <el-form-item label="SEO ключевая фраза UA" prop="seo_key_phrase">
          <el-input v-model="form.seo_key_phrase" />
          {{ $_ErrorMixin_getError('seo_key_phrase') }}
        </el-form-item>
        <el-form-item label="SEO ключевая фраза RU" prop="seo_key_phrase_ru">
          <el-input v-model="form.seo_key_phrase_ru" />
          {{ $_ErrorMixin_getError('seo_key_phrase_ru') }}
        </el-form-item>

        <el-form-item label="SEO Текст UA" prop="seo_text">
          <el-input v-model="form.seo_text" type="textarea" />
          {{ $_ErrorMixin_getError('seo_text') }}
        </el-form-item>
        <el-form-item label="SEO Текст RU" prop="seo_text_ru">
          <el-input v-model="form.seo_text_ru" type="textarea" />
          {{ $_ErrorMixin_getError('seo_text_ru') }}
        </el-form-item>

        <el-form-item label="Cтатус" prop="state">
          <el-select
            v-model="selectedState"
            placeholder="Select"
          >
            <el-option
              v-for="state in states"
              :key="state.value"
              :label="state.label"
              :value="state.value"
            />
          </el-select>
          {{ $_ErrorMixin_getError('state') }}
        </el-form-item>
      </el-form>
      <el-button type="success" @click="save">
        <span v-if="id">Изменить</span>
        <span v-else>Создать</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import ErrorMixin from '@/utils/ErrorMixin';
import CategorySelector from '../wishes/CategorySelector.vue';

let defaultValues = {
  slug: '',
  title: '',
  title_ru: '',
  description: '',
  description_ru: '',
  button_name: '',
  button_name_ru: '',
  button_link: '',
  button_link_ru: '',
  city_id: null,
  imagesIds: [],
  seo_text: '',
  seo_text_ru: '',
  categoriesIds: [],
  seo_title: '',
  seo_title_ru: '',
  seo_description: '',
  seo_description_ru: '',
  seo_key_phrase: '',
  seo_key_phrase_ru: '',
  state: '',
};

export default {
  name: 'TopicForm',
  components: {CategorySelector},
  mixins: [ErrorMixin],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      imageError: false,
      formTitle: null,
      formData: null,
      form: {},
      cities: null,
      cityOptions: [],
      city: null,
      selectedCityId: null,
      selectedCategory:'',
      selectedImage: null,
      selectedState: 'DRAFT',
      states: [
        {value: 'PUBLISHED', label:'PUBLISHED'},
        {value: 'DRAFT', label:'DRAFT'}],
      filterObject: {
        page: 1,
        search: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        },
      },
        rules: {
          slug: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (/\s/.test(value)) {
                  callback(new Error('Slug не должен содержать пробелы'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],

          title: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 100, message: 'Заголовок UA должен быть от 3 до 100 символов', trigger: 'blur' },
          ],
          title_ru: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 100, message: 'Заголовок RU должен быть от 3 до 100 символов', trigger: 'blur' },
          ],

          description: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 180, message: 'Описание UA должно быть от 3 до 180 символов', trigger: 'blur' },
          ],
          description_ru: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 180, message: 'Описание RU должно быть от 3 до 8000 180', trigger: 'blur' },
          ],

          button_name: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 25, message: 'Название кнопки UA должно быть от 3 до 25 символов', trigger: 'blur' },
          ],
          button_name_ru: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 25, message: 'Название кнопки RU должно быть от 3 до 25 символов', trigger: 'blur' },
          ],
          button_link: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 255, message: 'Ссылка UA должна быть от 3 до 255 символов', trigger: 'blur' },
            { type: 'url', message: 'Это поле должно быть ссылкой', trigger: 'blur' },
          ],
          button_link_ru: [
            { required: true, message: 'Обязательное поле', trigger: 'blur' },
            { min: 3, max: 255, message: 'Ссылка UA должна быть от 3 до 255 символов', trigger: 'blur' },
            { type: 'url', message: 'Это поле должно быть ссылкой', trigger: 'blur' },
          ],

          seo_text: [{ max: 10000, message: 'SEO Text UA должно быть до 10000 символов', trigger: 'blur' }],
          seo_text_ru: [{ max: 10000, message: 'SEO Text RU должно быть до 10000 символов', trigger: 'blur' }],

          seo_title: [{ max: 255, message: 'SEO Title UA должен быть до 255 символов', trigger: 'blur' }],
          seo_title_ru: [{ max: 255, message: 'SEO Title RU должен быть до 255 символов', trigger: 'blur' }],

          seo_description: [{ max: 255, message: 'SEO Description UA должен быть до 255 символов', trigger: 'blur' }],
          seo_description_ru: [{ max: 255, message: 'SEO Description RU должен быть до 255 символов', trigger: 'blur' }],

          seo_key_phrase: [{ max: 255, message: 'SEO ключевая фраза UA должен быть до 255 символов', trigger: 'blur' }],
          seo_key_phrase_ru: [{ max: 255, message: 'SEO ключевая фраза RU должен быть до 255 символов', trigger: 'blur' }],

          imagesIds: [
            { required: true, message: 'Обязательное поле', trigger: ['blur', 'change']  },
          ],

          categoriesIds: [
            { required: true, message: 'Обязательное поле', trigger: ['blur', 'change']  },
            { type: 'array', message: 'Категории должны быть массивом', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (value.length === 0) {
                  callback(new Error('Нужно выбрать хотя бы одну категорию'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
      },
    }
  },
  computed: {
    ...mapState('wishes/categories', [
      'categories',
    ]),
    generateSlugPreview() {
      if (this.form.slug) {
        return `egolist.ua/topic-${this.form.slug}`;
      } else {
        return '';
      }
    },
  },
  async created() {
    this.cityOptions = await this.preloadCities()
    if (this.id) {
      await this.getTopicForEdit()
    } else {
      this.form = {...defaultValues}
    }
  },
  methods:{
    ...mapActions('topics', [
      'createTopic', 'getTopicById', 'editTopic', 'uploadTopicImage',
    ]),
    ...mapActions('cities', [
      'fetchCities','preloadCities',
    ]),
    ...mapActions('wishes/categories', ['fetchCategories']),

    async upLoad() {
      this.formData = await new FormData()
      await  this.formData.append('file', this.selectedImage, this.selectedImage.name)
      try {
        this.selectedImage = await this.uploadTopicImage(this.formData)
        this.imageError = false
      } catch (e){
        this.$_ErrorMixin_handleError(e)
        this.imageError = true
      }
    },
    async onFileSelect(res) {
      this.$refs.upload.clearFiles()
      this.selectedImage = await res.file
      await this.upLoad()
    },

    selectedCityChange() {
      this.form.city_id = this.selectedCityId
    },

    async getTopicForEdit () {
      const data = await this.getTopicById(this.id);
      const topic = data.data;
      this.form = {
        id: topic.id,
        slug: topic.slug,
        title: topic.title,
        title_ru: topic.title_ru,
        description: topic.description,
        description_ru: topic.description_ru,
        button_name: topic.button_name,
        button_name_ru: topic.button_name_ru,
        button_link: topic.button_link,
        button_link_ru: topic.button_link_ru,
        imagesIds: [],
        city_id: topic.city?.id ? topic.city.id : null,
        seo_text: topic.seo_text,
        seo_text_ru: topic.seo_text_ru,
        categoriesIds: [],
        seo_title: topic.seo_title,
        seo_title_ru: topic.seo_title_ru,
        seo_description: topic.seo_description,
        seo_description_ru: topic.seo_description_ru,
        seo_key_phrase: topic.seo_key_phrase,
        seo_key_phrase_ru: topic.seo_key_phrase_ru,
        state: topic.state,
      };

      this.selectedState = topic.state;

      topic.categories.forEach((category) =>
      {
        this.form.categoriesIds.push(category.id)
      });

      this.selectedImage = topic.first_image;

      topic.city?.id ? this.selectedCityId = topic.city.id : null;
    },
    async  getCity(query) {
      if (query !== '') {
        this.filterObject.search = query
        this.cityOptions = await this.fetchCities(this.filterObject)
      }
    },
    async save() {
      this.beforeSave();
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.id ? await this.editTopic(this.form) : await this.createTopic(this.form);
            this.resetDefaultValues();
            await this.$router.push({ name: 'TopicsPage' });
          } catch (e) {
            this.$_ErrorMixin_handleError(e);
          }
        } else {
          return false;
        }
      });
    },
    addCategory() {
      if (this.selectedCategory) {
        if (this.form.categoriesIds.includes(this.selectedCategory[this.selectedCategory.length - 1])) {
          this.$alert('Эта категория уже добавлена')
          this.selectedCategory = ''
          return
        }
        this.form.categoriesIds.push(this.selectedCategory[this.selectedCategory.length - 1])
        this.selectedCategory = ''
        this.$refs.form.validateField('categoriesIds');
      }
    },
    deleteCategory(id) {
      let index = this.form.categoriesIds.indexOf(id)
      if (index !== -1) {
        this.form.categoriesIds.splice(this.form.categoriesIds.indexOf(id), 1)
      }
    },
    deleteCity() {
      this.form.city_id = null
      this.selectedCityId = null
    },
    beforeSave() {
      this.form.state = this.selectedState
      if (this.selectedImage) {
        this.form.imagesIds.push(this.selectedImage.id)
      }
    },
    resetDefaultValues() {
      defaultValues = {
        slug: '',
        title: '',
        title_ru: '',
        description: '',
        description_ru: '',
        button_name: '',
        button_name_ru: '',
        button_link: '',
        button_link_ru: '',
        city_id: null,
        imagesIds: [],
        seo_text: '',
        seo_text_ru: '',
        categoriesIds: [],
        seo_title: '',
        seo_title_ru: '',
        seo_description: '',
        seo_description_ru: '',
        seo_key_phrase: '',
        seo_key_phrase_ru: '',
        state: '',
      };
    },
  },
}
</script>

<style lang="sass" scoped>

.uploaded-image
  height: 180px

.uploaded-image-container
  padding-left: 100px

.inline-form-container
  display: flex

.slug-preview
  margin-top: 5px
  font-style: italic
  color: #888


.avatar-uploader .el-upload
    border: 1px dashed #d9d9d9
    border-radius: 6px
    cursor: pointer
    position: relative
    overflow: hidden

.avatar-uploader .el-upload:hover
    border-color: #409EFF

.avatar-uploader-icon
    font-size: 28px
    color: #8c939d
    width: 178px
    height: 178px
    line-height: 178px
    text-align: center

.avatar
    width: 178px
    height: 178px
    display: block

</style>
