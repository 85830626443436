<template>
  <el-table
    :data="list && list.data"
    :default-sort="{prop: 'created_at', order: 'descending'}"
    :row-class-name="tableRowClassName"
    style="width: 100%"
    @sort-change="onSortChange"
  >
    <el-table-column label="Изображение" width="110">
      <template slot-scope="scope">
        <TenderImageColumn :tender="scope.row" />
      </template>
    </el-table-column>

    <el-table-column label="Название" prop="name">
      <template slot-scope="scope">
        <ProductInfo :product="scope.row" />
      </template>
    </el-table-column>

    <el-table-column align="center" label="Топ" width="80">
      <template slot-scope="scope">
        <el-switch
          active-color="#13ce66"
          :value="scope.row.is_top"
          @change="(val) => changeTopStatus(val, scope.row)"
        />
      </template>
    </el-table-column>

    <el-table-column
      label="Дата создания"
      prop="created_at"
      sortable="custom"
      width="150"
    />

    <el-table-column label="Пользователь" prop="name">
      <template slot-scope="scope">
        <UserCard :user="scope.row.user" />
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Статус"
      width="150"
    >
      <template slot-scope="scope">
        <el-tag
          class="type-tag"
          size="small"
          :type="getStatusTagType(scope.row.state)"
        >
          {{ scope.row.state && scope.row.state.replace('_', ' ') }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Админ. статус"
      width="170"
    >
      <template slot-scope="scope">
        <el-dropdown
          class="btn-item"
          trigger="click"
          @command="(command) => changeStatus(command, scope.row)"
        >
          <span class="el-dropdown-link">
            {{ scope.row.admin_status || 'Выберите статус' }}
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(value, key) in adminStatuses"
              :key="key"
              :command="value"
            >
              {{ value }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>

    <el-table-column
      align="right"
      fixed="right"
      width="130"
    >
      <template slot-scope="scope">
        <el-button-group>
          <el-tooltip
            content="Просмотр в админке"
            :open-delay="0"
          >
            <el-button
              size="small"
              type="success"
              @click="onView(scope.row.id)"
            >
              <i class="far fa-eye" />
            </el-button>
          </el-tooltip>

          <el-tooltip
            content="Просмотр на сайте"
            :open-delay="0"
          >
            <a
              v-if="scope.row.frontend_url"
              class="el-button el-button--info el-button--small"
              :href="scope.row.frontend_url"
              target="_blank"
            >
              <i class="far fa-eye" /><i class="fas fa-external-link-alt" style="margin-left: 8px;" />
            </a>
          </el-tooltip>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import UserCard from '@/components/user-card/UserCard';
import TenderImageColumn from '@/components/column-components/TenderImageColumn';
import ProductInfo from '@/components/common/ProductsInfo.vue';

export default {
  name: 'List',
  components: {ProductInfo, TenderImageColumn, UserCard},
  data() {
    return {
      editMode: false,
      adminStatusesLoaded: false,
      localAdminStatuses: [
      { key: 'DEFAULT', label: 'Без статусу' },
      ],
    }
  },
  computed: {
    ...mapState('products', {
      list: 'products',
    }),
    ...mapGetters('products', [
      'getProductStateByKey',
      'getProductAdminStatusByKey',
      'productAdminStatusesTransformed',
    ]),
    ...mapGetters('value-objects', [
      'getStatusTagType',
    ]),
    adminStatuses() {
      return this.localAdminStatuses.reduce((acc, status) => {
        acc[status.key] = status.label;
        return acc;
      }, {});
    },
  },
  async created() {
    await this.fetchProductStates()
    await this.loadAdminStatuses()
  },
  methods: {
    ...mapActions('products', [
      'fetchProductStates',
      'fetchProductAdminStatuses',
      'saveAdminStatus',
      'changeProductTopStatus',
    ]),
    async loadAdminStatuses() {
      if (!this.adminStatusesLoaded) {
        await this.fetchProductAdminStatuses()
        this.localAdminStatuses = [
          { key: 'DEFAULT', label: 'Без статусу' },
          ...this.productAdminStatusesTransformed,
        ]
        this.adminStatusesLoaded = true
      }
    },
    onView(id) {
      this.$router.push({name: 'ViewProducts', params: {id}})
    },
    onSortChange(val) {
      if (val.prop === 'created_at') {
        this.$emit('sort', {
          sort: val.order === 'ascending' ? 'oldest' : 'newest',
        })
      }
    },
    tableRowClassName({row}) {
      if (row.type === 'internal') {
        return 'warning-row';
      }
      return '';
    },
    changeStatus(status, row) {
      if (status === 'Без статусу') {
        status = null;
      }
      this.saveAdminStatus({id: row.id, status: status})
        .then(() => {
          this.$set(row, 'admin_status', status)

          this.$message({
            message: 'Статус успешно обновлен',
            type: 'success',
          })
        })
        .catch(error => {
          console.error('Ошибка при обновлении статуса:', error)
          this.$message({
            message: 'Ошибка при обновлении статуса',
            type: 'error',
          })
        })
    },

    async changeTopStatus(val, product) {
      product.is_top = val

      try {
        await this.changeProductTopStatus({id: product.id, params: {is_top: val}})
        await this.$message.success('Статус успешно изменен');
      } catch (e) {
        await this.$message.error('Статус изменить не удалось');
        product.is_top = !val
      }

    },
  },
}
</script>

<style lang="sass">
@import "@/assets/sass/_variables"

.type-tag
  text-transform: uppercase
  white-space: pre-wrap
  height: auto
  line-height: 1.4
  padding: 5px

.name-wrapper
  display: flex
  align-items: center

.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0

</style>
