import api from '@/utils/api';

export default {
  state: () => ({
    categories: [],
    categoriesPromise: null,
    categoriesDetails: null,
    isInitialized: false,
  }),
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCategoriesPromise(state, categoriesPromise) {
      state.categoriesPromise = categoriesPromise;
    },
    setIsInitialized(state) {
      state.isInitialized = true;
    },
    setCategoriesDetails(state, payload) {
      state.categoriesDetails = payload;
    },
  },
  getters: {
    categoriesDetails: state => state.categoriesDetails,
    getCategoriesForSelector(state) {
      const mapper = categories => {
        return categories.map(item => {
          let result = {
            value: item.id,
            label: item.name,
            disabled: item.is_hidden,
          };

          if (item.has_children) {
            result.children = mapper(item.children);
          }

          return result;
        });
      };

      return mapper(state.categories);
    },
    getCategoriesForAdditionalSelector(state) {
      const mapper = categories => {
        return categories.map(item => {
          let result = {
            value: item.id,
            label: item.name,
          };

          if (item.has_children) {
            result.children = mapper(item.children);
          }

          return result;
        });
      };

      return mapper(state.categories);
    },
  },
  actions: {
    fetchCategories({ state, commit }) {
      if (state.isInitialized) {
        return new Promise(resolve => {
          resolve(state.categories);
        });
      }

      if (state.categoriesPromise) {
        return state.categoriesPromise;
      }

      const categoriesPromise = api.http.get('admin/categories');

      categoriesPromise
        .then(response => {
          commit('setCategories', response.data.data);
          commit('setCategoriesPromise', null);
          commit('setIsInitialized');

          return response.data.data;
        })
        .catch(() => {
          commit('setCategoriesPromise', null);
        });

      return categoriesPromise;
    },
    async updateImages(_, { id, formData }) {
      try {
        const res = await api.http.post(`admin/categories/${id}/image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return res.data; // Return the response if necessary
      } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
      }
    },
    async createCategories(_, form) {
      const response =  await api.http.post('admin/categories', form);
      console.log('Response from createCategories:', response);
      return response.data?.data;
    },

    // Редагування події
    async updateCategories(_, { id, form }) {
      await api.http.post(`admin/categories/${id}/update`, form);
    },
    async deleteCategories(_, id) {
      await api.http.delete(`admin/categories/${id}/delete`);
    },

    async getCategoriesDetails({ commit }, id) {
      const res = await api.http.get(`admin/categories/${id}`);
      commit('setCategoriesDetails', res?.data?.data);
      return res?.data?.data;
    },
  },
  namespaced: true,
};
