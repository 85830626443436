<template>
  <el-table :data="eventsList" stripe style="width: 100%">
    <el-table-column label="Зображення" width="150">
      <template slot-scope="scope">
        <img alt="Зображення події" :src="scope.row.image" style="width: 100%; height: auto;">
      </template>
    </el-table-column>
    <el-table-column label="Названия события UA" min-width="300" prop="title" />
    <el-table-column label="Названия события RU" min-width="300" prop="title_ru" />
    <el-table-column fixed="right" width="120">
      <template slot-scope="scope">
        <el-button size="small" type="success" @click="onEdit(scope.row.id)">
          <i class="far fa-edit" />
        </el-button>

        <el-button size="small" type="danger" @click="onDelete(scope.row.id)">
          <i class="far fa-trash-alt" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'List',
  computed: {
    ...mapState('eventsMain', {
      eventsList: state => state.eventsList,
    }),
  },
  methods: {
    ...mapActions('eventsMain', ['deleteEvent', 'fetchEventsList']),
    async onEdit(id) {
      this.$router.push({ name: 'UpdateEventsMain', params: { id } });
    },
    async onDelete(id) {
      try {
        const res = await this.$confirm('Ви впевнені, що хочете видалити цю подію?');
        if (res) {
          await this.deleteEvent(id);
          await this.fetchEventsList();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
