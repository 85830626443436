<template>
  <div>
    <div class="title-bar">
      <h1>{{ isUpdate ? 'Редактировать Категорию' : 'Создать Категорию' }}</h1>

      <div class="title-bar__button-wrapper">
        <router-link class="button-wrapper-link" :to="{name: 'CategoriesTree'}">
          <el-button type="info">
            <i class="fas fa-list" /> Список
          </el-button>
        </router-link>

        <!-- <el-button type="primary" @click="$refs.form.onSubmit()">
          Сохранить
        </el-button> -->
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <Form ref="form" />
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/pages/categories/TreeForm';

export default {
  name: 'CreateUpdateTree',
  metaInfo: {
    title: 'Создать/Редактировать Категорию',
  },
  components: {Form},
  computed: {
    isUpdate() {
      return this.$route.name === 'UpdateCategoriesTree'
    },
  },
}
</script>

