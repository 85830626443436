<template>
  <div class="user-publications">
    <MainPanel v-loading="loading">
      <h2>Публікації виконавця</h2>
      <div v-if="products.length" style="padding-bottom: 20px">
        <h3>Оголошення:</h3>
        <el-table
          :data="products"
          style="width: 100%"
        >
          <el-table-column
            label="Назва"
            prop="name"
            width="240"
          />
          <el-table-column
            label="Статус"
            prop="state"
            width="180"
          />
          <el-table-column
            label="Дивитись в адмінці"
            prop="state"
            width="240"
          >
            <template slot-scope="scope">
              <button
                class="el-button"
                @click="viewProduct(scope.row.id)"
              >
                Дивитись оголошення
              </button>
            </template>
          </el-table-column>
          <el-table-column
            label="Посилання на сайт"
            prop="frontend_url"
          >
            <template slot-scope="scope">
              <a :href="scope.row.frontend_url" target="_blank">{{ scope.row.frontend_url }}</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="offers.length" style="padding-bottom: 20px">
        <h3>Пропозиції:</h3>
        <el-table
          :data="offers"
          style="width: 100%"
        >
          <el-table-column
            label="Назва"
            prop="name"
            width="240"
          />
          <el-table-column
            label="Статус"
            prop="state"
            width="180"
          />
          <el-table-column
            label="Дивитись в адмінці"
            prop="state"
            width="240"
          >
            <template slot-scope="scope">
              <button
                class="el-button"
                @click="viewOffer(scope.row.id)"
              >
                Дивитись пропозицію
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="wishes.length" style="padding-bottom: 20px">
        <h3>Замовлення:</h3>
        <el-table
          :data="wishes"
          style="width: 100%"
        >
          <el-table-column
            label="Назва"
            prop="name"
            width="240"
          />
          <el-table-column
            label="Статус"
            prop="state"
            width="180"
          />
          <el-table-column
            label="Дивитись в адмінці"
            prop="state"
            width="240"
          >
            <template slot-scope="scope">
              <button
                class="el-button"
                @click="viewWish(scope.row.id)"
              >
                Дивитись замовлення
              </button>
            </template>
          </el-table-column>
          <el-table-column
            label="Посилання на сайт"
            prop="frontend_url"
          >
            <template slot-scope="scope">
              <a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-if="!products.length && !offers.length && !wishes.length"
        style="display: flex; justify-content: center; align-items: center; height: 100%;"
      >
        <h3>У користувача не знайдено публікацій</h3>
      </div>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel.vue';
import {mapActions} from 'vuex';

export default {
  name: 'UserPublications',
  components: {MainPanel},
  props: {
    user: {type: Object, required: true},
  },
  data() {
    return {
      products: [],
      offers: [],
      wishes: [],
      loading: false,
    }
  },
  async created() {
    await this.getUserPublications();
  },
  methods: {
    ...mapActions('products', [
      'fetchProductsByUserSlug',
    ]),

    ...mapActions('offers', [
      'fetchOffersByUserSlug',
    ]),

    ...mapActions('wishes', [
      'fetchWishesByUserSlug',
    ]),
    async getUserPublications() {
      this.loading = true;
      this.products = await this.fetchProductsByUserSlug(this.user.slug);
      this.offers = await this.fetchOffersByUserSlug(this.user.slug);
      this.wishes = await this.fetchWishesByUserSlug(this.user.slug);
      this.loading = false;
    },
    viewProduct(id) {
      const url = this.$router.resolve({ name: 'ViewProducts', params: { id } }).href;
      window.open(url, '_blank');
    },

    viewOffer(id) {
      const url = this.$router.resolve({ name: 'ViewOffer', params: { id } }).href;
      window.open(url, '_blank');
    },

    viewWish(id) {
      const url = this.$router.resolve({ name: 'ViewWish', params: { id } }).href;
      window.open(url, '_blank');
    },
  },
}
</script>
