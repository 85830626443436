import CreateUpdatePage from '../pages/events-main/CreateUpdatePage.vue';
import ListPage from '../pages/events-main/ListPage.vue';
export default [
  {
    path: '/events-main/list',
    name: 'EventsMainList',
    component: ListPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events-main/create',
    name: 'CreateEventsMain',
    component: CreateUpdatePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events-main/update/:id',
    name: 'UpdateEventsMain',
    component: CreateUpdatePage,
    meta: {
      requiresAuth: true,
    },
  },
];
