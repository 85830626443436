<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <div class="row">
      <!-- <div class="col-md-8">
        <el-form-item label="Названия UA" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>
      </div>
      <div class="col-md-8">
        <el-form-item label="Названия RU" prop="title_ru">
          <el-input v-model="form.title_ru" />
        </el-form-item>
      </div> -->
      <!-- <div class="col-md-8">
        <el-form-item label="SEO заголовок UA" prop="seo_title">
          <el-input v-model="form.seo_title" />
        </el-form-item>
      </div> -->
      <!-- <div class="col-md-8">
        <el-form-item label="SEO заголовок RU" prop="seo_title_ru">
          <el-input v-model="form.seo_title_ru" />
        </el-form-item>
      </div>
      <div class="col-md-8">
        <el-form-item label="Slug SEO" prop="slug_seo">
          <el-input v-model="form.slug_seo" />
        </el-form-item>
      </div>
      <div class="col-md-8">
        <el-form-item label="Родительская категория">
          <el-select v-model="selectedCategoryId" placeholder="Виберіть категорію" style="width: 100%">
            <el-option
              v-for="category in getCategoriesForSelector"
              :key="'category-' + category.label"
              :disabled="category.disabled"
              :label="category.label"
              :value="category.value"
            />
          </el-select>
        </el-form-item>
      </div> -->
      <div class="col-md-8">
        <el-form-item label="Изображение категории">
          <input id="file-cat" type="file" @change="handleFileChange">
          <label class="btn-file" for="file-cat">Загрузить</label>
          <div v-if="fileName">
            <p>{{ fileName }}</p>
            <button class="el-button el-button--info" type="button" @click="removeFile">Удалить</button>
          </div>
        </el-form-item>
        <el-form-item
          v-if="form.image"
          label="Изображение категории"
          prop="image"
        >
          <img alt="form.title" :src="form.image" style="width: 150px; height: 150px; object-fit: scale-down;">
        </el-form-item>
      </div>
      <div class="col-md-8">
        <el-form-item label="Позиция" prop="position">
          <el-input-number v-model="form.position" :min="1" />
        </el-form-item>
      </div>
      <div class="col-md-8">
        <el-form-item
          label="Показывать на главной"
          prop="is_main"
          style="display: flex; align-items: center;
        gap: 16px; margin-left: -16px;"
        >
          <!-- <el-checkbox v-model="form.is_main">Головний запис</el-checkbox> -->
          <el-switch
            v-model="form.is_main"
            active-color="#67C23A"
            :active-value="true"
            inactive-color="#dcdfe6"
            :inactive-value="false"
          />
        </el-form-item>
      </div>
    </div>

    <div class="text-right">
      <el-button native-type="submit" type="primary">
        Сохранить
      </el-button>
    </div>
  </el-form>
</template>

<script>
// import Redactor from '@/components/common/WysiwygRedactor'
// import { id } from 'date-fns/locale';
import ErrorMixin from '@/utils/ErrorMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CategoriesForm',
  mixins: [ErrorMixin],
  data() {
    return {
      form: {
        id: null,
        title: '',
        title_ru: '',
        seo_title: '',
        seo_title_ru: '',
        position: 1,
        image: null,
        is_main: false,
        parent_id: null,
        slug_seo: '',
      },
      categories: [],
      isUpdateCat: false,
      selectedCityId: null,
      selectedImage: null,
      selectedCategoryId: null,
      imageError: false,
      categoryId: '',
      fileName: '', // для збереження імені файлу
      file: null,   // для збереження самого файлу
      formData: null,
      rules: {
        title: [{ required: true, message: 'Поле обов’язкове', trigger: 'blur' }],
        title_ru: [{ required: true, message: 'Поле обов’язкове', trigger: 'blur' }],
        slug_seo: [{ required: true, message: 'Поле обов’язкове', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters('wishes/categories', ['getCategoriesForSelector']),
    isUpdate() {
      return this.$route.name === 'UpdateCategoriesTree';
    },
  },
  watch: {
    selectedCategoryId(newValue) {
      this.form.parent_id = newValue;
    },
  },
  created() {
    this.getParamsFromRouter();
    this.fetchCategories();
  },
  methods: {
    ...mapActions('wishes/categories', [
      'getCategoriesDetails',
      'createCategories',
      'updateCategories',
      'fetchCategories',
      'updateImages',
    ]),
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('image', file);
        this.formData = formData;
        this.fileName = file.name;
      }
    },
    removeFile() {
      this.fileName = ''; // Очищаємо ім'я файлу
      this.file = null;   // Очищаємо сам файл
      this.$refs.fileInput.value = ''; // Очищаємо значення input
    },
    async onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const eventData = {
            title: this.form.title,
            title_ru: this.form.title_ru,
            seo_title: this.form.seo_title,
            seo_title_ru: this.form.seo_title_ru,
            position: this.form.position,
            is_main: this.form.is_main,
            parent_id: this.form.parent_id,
            slug_seo: this.form.slug_seo,
          };

          try {
            let response;

            if (this.isUpdate) {
              response = await this.updateCategories({ id: this.categoryId, form: eventData });
            } else {
              response = await this.createCategories(eventData);
              this.categoryId = response.id;
            }

            if (this.formData) {
              const formData = this.formData;
              await this.updateImages({ id: this.categoryId, formData });
            }

            await this.fetchCategories();
            await this.$router.push({ name: 'CategoriesTree', params: { id: this.categoryId } });
            window.location.reload();
          } catch (error) {
            this.$_ErrorMixin_handleError(error);
          }
        }
      });
    },
    async fetchCategories() {
      await this.$store.dispatch('wishes/categories/fetchCategories');
    },
    async getParamsFromRouter() {
      const id = this.$route.params.id;
      if (id) {
        const model = await this.getCategoriesDetails(id);
        this.categoryId = id;
        this.isUpdateCat = true;
        this.fillForm(model);
      }
    },
    fillForm(model) {
      this.form.title = model.title || '';
      this.form.title_ru = model.title_ru || '';
      this.form.seo_title = model.seo_title || '';
      this.form.seo_title_ru = model.seo_title_ru || '';
      this.form.position = model.position || 1;
      this.form.is_main = model.is_main || false;
      this.form.parent_id = model.parent_id || '';
      this.form.slug_seo = model.slug_seo || '';
      this.form.image = model.image || '';
      this.selectedCategoryId = model.parent_id || '';
    },
  },
};
</script>
<style scoped>
input[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

input[type="file"] + label.btn-file {
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 10px;
  outline: none;
  padding: 8px 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  background-color: #99c793;
  border-radius: 50px;
  overflow: hidden;
}

input[type="file"] + label.btn-file::before  {
      color: #fff;
      content: "";
      height: 16px;
      width: 16px;
      right: 120%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s;
      background: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center no-repeat;
}


input[type=file] + label.btn-file:hover {
  background-color: #497f42;
}

input[type=file] + label.btn-file:hover::before {
  right: 78%;
}
</style>
