import CreateUpdateTree from '../pages/categories/CreateUpdateTree.vue';
import TreePage from '@/pages/categories/TreePage';

export default [
  {
    path: '/categories',
    name: 'CategoriesTree',
    component: TreePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/categories/create',
    name: 'CreateCategoriesTree',
    component: CreateUpdateTree,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/categories/update/:id',
    name: 'UpdateCategoriesTree',
    component: CreateUpdateTree,
    meta: {
      requiresAuth: true,
    },
  },
];
