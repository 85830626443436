<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-md-8">
        <el-form-item label="Названия UA" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>
      </div>
      <div class="col-md-8">
        <el-form-item label="Названия RU" prop="title_ru">
          <el-input v-model="form.title_ru" />
        </el-form-item>
      </div>
      <div class="col-md-8">
        <el-form-item label="Категории" prop="categories">
          <el-select
            v-model="form.categories"
            multiple
            placeholder="Выберите категории"
            style="width: 100%"
            value-key="id"
          >
            <el-option-group
              v-for="group in categoriesList"
              :key="group.id"
              :label="group.name"
            >
              <el-option
                v-for="category in group.children"
                :key="category.id"
                :label="category.name"
                :value="category.id"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-8">
        <el-form-item label="Изображение" prop="image">
          <input id="file" type="file" @change="onFileChange">
          <label class="btn-file" for="file">Загрузить</label>
          <div v-if="fileName">
            <p>{{ fileName }}</p>
            <button class="el-button el-button--info" type="button" @click="removeFile">Удалить</button>
          </div>
        </el-form-item>
        <el-form-item v-if="form.image" label="Изображение категории" prop="image">
          <img alt="form.title" :src="form.image" style="width: 150px; height: 150px; object-fit: scale-down;">
        </el-form-item>
      </div>
      <div class="col-md-4">
        <el-form-item label="Позиция" prop="position">
          <el-input-number v-model="form.position" :min="1" />
        </el-form-item>
      </div>
      <div class="col-md-4">
        <el-form-item
          label="Показывать на главной"
          prop="is_main"
          style="display: flex; align-items: center;
        gap: 16px; margin-left: -16px;"
        >
          <el-switch
            v-model="form.is_main"
            active-color="#67C23A"
            :active-value="true"
            inactive-color="#dcdfe6"
            :inactive-value="false"
          />
        </el-form-item>
      </div>
    </div>

    <div class="text-right">
      <el-button type="primary" @click="onSubmit">
        Сохранить
      </el-button>
    </div>
  </el-form>
</template>

<script>
// import Redactor from '@/components/common/WysiwygRedactor'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EventForm',
  data() {
    return {
      form: {
        title: '',
        title_ru: '',
        position: 1,
        is_main: false,
        image: null,
      },
      imageUrl: null,
      fileName: '', // для збереження імені файлу
      file: null,   // для збереження самого файлу
      rules: {
        title: [{ required: true, message: 'Поле обов’язкове', trigger: 'blur' }],
        title_ru: [{ required: true, message: 'Поле обов’язкове', trigger: 'blur' }],
        categories: [{ required: true, message: 'Поле обов’язкове', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters('eventsMain', ['categoriesList']),
    isUpdate() {
      return this.$route.name === 'UpdateEventsMain';
    },
  },
  created() {
    this.fetchCategories();
    this.getParamsFromRouter();

    console.log(this.categoriesList)
  },
  methods: {
    ...mapActions('eventsMain', ['getEventDetails', 'createEvent', 'updateEvent', 'fetchCategories']),
    async onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const eventData = new FormData(); // Використовуємо FormData для відправки файлів
          for (const [key, value] of Object.entries(this.form)) {
            if (key === 'categories' && Array.isArray(value)) {
              // Додаємо кожен елемент масиву окремо
              const value = Array.isArray(this.form.categories)
            ? this.form.categories.map(category => (typeof category === 'object' ? category.id : category))
            : [];
              value.forEach((category, index) => {
                eventData.append(`categories[${index}]`, category);
              });
            } else if (key === 'is_main') {
              eventData.append(key, value ? 1 : 0);
            } else if (key === 'image') {
              // Якщо це файл
              if (value instanceof File) {
                eventData.append(key, value);
              } else if (typeof value === 'string' && value.startsWith('http')) {
                eventData.append('image', value);
              }
            } else {
              // Додаємо звичайні дані
              eventData.append(key, value || '');
            }
          }

          for (const [key, value] of eventData.entries()) {
            console.log(`${key}:`, value);
          }
          // Визначаємо функцію для створення або оновлення події
          // const saveAction = this.isUpdate ? this.updateEvent : this.createEvent;

          const id = this.$route.params.id;
          if (id) {
            // Якщо є ID, оновлюємо подію
            await this.updateEvent({ id, form: eventData });
          } else {
            // Якщо ID немає, створюємо нову подію
            await this.createEvent(eventData);
          }
          try {
            // Відправляємо форму
            // await saveAction({ id: this.$route.params.id, form: eventData });
            // await saveAction(eventData);
            // Переходимо до списку після успішного збереження
            await this.$router.push({ name: 'EventsMainList' });
          } catch (error) {
            console.error('Помилка під час збереження:', error);
          }
        }
      });
    },
    async fetchCategories() {
      await this.$store.dispatch('eventsMain/fetchCategories');
      // this.categories = this.categoriesList; // присвоюємо отримані категорії
    },
    onChangeCategory () {

    },
    onFileChange(event) {
      const file = event.target.files[0]; // Отримуємо вибраний файл
      if (file) {
        this.form.image = file; // Зберігаємо файл у form.image
        this.fileName = file.name;
      }
    },
    removeFile() {
      this.fileName = ''; // Очищаємо ім'я файлу
      this.form.image = null;   // Очищаємо сам файл
      this.$refs.fileInput.value = ''; // Очищаємо значення input
    },
    async getParamsFromRouter() {
      const id = this.$route.params.id;
      if (id) {
        const model = await this.getEventDetails(id);
        this.fillForm(model);
      }
    },
    fillForm(model) {
      this.form.title = model.title || '';
      this.form.title_ru = model.title_ru || '';
      this.form.image = model.image ? model.image : null;
      this.form.position = model.position || 1;
      this.form.is_main = model.is_main || false;
      this.form.categories = model.categories.map(i => i.id);
      // this.form.slug = model.slug || '';
      // this.form.slug_seo = model.slug_seo || '';
    },
  },
};
</script>
<style scoped>
input[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

input[type="file"] + label.btn-file {
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 10px;
  outline: none;
  padding: 8px 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  background-color: #99c793;
  border-radius: 50px;
  overflow: hidden;
}

input[type="file"] + label.btn-file::before {
  color: #fff;
  content: "";
  height: 16px;
  width: 16px;
  right: 120%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  background: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    center no-repeat;
}

input[type="file"] + label.btn-file:hover {
  background-color: #497f42;
}

input[type="file"] + label.btn-file:hover::before {
  right: 78%;
}
</style>
