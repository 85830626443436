<template>
  <div>
    <!-- <PageHeader page-name="Категории">
      <template>
        <router-link :to="{ name: 'CreateCategoriesTree' }">
          <el-button type="primary">
            Создать Категорию
          </el-button>
        </router-link>
      </template>
    </PageHeader> -->
    <MainPanel>
      <el-tree v-loading="!categories.length" :data="categories">
        <span slot-scope="{ node, data }" class="category-node">
          <span>
            {{ data.name }}
            ({{ data.name_ua }})
          </span>
          <div class="category-node-btn">
            <el-button size="small" type="success" @click="onEdit(data.id)">
              <i class="far fa-edit" />
            </el-button>

            <!-- <el-button size="small" type="danger" @click="onDelete(data.id)">
              <i class="far fa-trash-alt" />
            </el-button> -->
          </div>
          <a :href="data.url" target="_blank">На сайт</a>
        </span>
      </el-tree>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel';
import { mapActions, mapGetters, mapState } from 'vuex';
// import PageHeader from '@/components/common/PageHeader';

export default {
  name: 'TreePage',
  components: { MainPanel },
  computed: {
    ...mapGetters('wishes/categories', ['getCategoriesForSelector']),
    ...mapState('wishes/categories', ['categories']),
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    ...mapActions('wishes/categories', ['deleteCategories', 'fetchCategories']),
    async onEdit(id) {
      if (!id) {
        console.error('ID is undefined');
        return;
      }
      this.$router.push({ name: 'UpdateCategoriesTree', params: { id } });
    },
    // async onDelete(id) {
    //   try {
    //     const res = await this.$confirm('Вы уверены, что хотите удалить категорию?');
    //     if (res) {
    //       await this.deleteCategories(id);
    //       window.location.reload();
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
};
</script>

<style scoped>
.category-node {
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}
.category-node-btn {
  margin-left: auto;
  margin-right: 10px;
}

.el-tree {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
</style>
