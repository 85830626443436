<template>
  <div>
    <PageHeader link-name="Назад" link-path="GeoList" page-name="Город" />
    <MainPanel v-loading="loading">
      <table v-if="cityPage" class="key-values-table">
        <tr>
          <td>Название города</td>
          <td>{{ cityPage.name }}</td>
        </tr>
        <tr>
          <td>Назва міста</td>
          <td>{{ cityPage.name_ua }}</td>
        </tr>
        <tr>
          <td>Название области</td>
          <td>{{ cityPage.region.name }}</td>
        </tr>
        <tr>
          <td>Назва області</td>
          <td>{{ cityPage.region.name_ua }}</td>
        </tr>
        <tr>
          <td>Показывать в списке городов сразу</td>
          <td>
            <el-tooltip placement="top">
              <el-switch
                v-model="cityPage.is_preloadable"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="changeStatus"
              />
            </el-tooltip>
          </td>
        </tr>
        <tr>
          <td>Показывать в списке городов в блоке "Послуги за категоріями"</td>
          <td>
            <el-tooltip placement="top">
              <el-switch
                v-model="cityPage.is_event_categories"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="onToggleEventCategories"
              />
            </el-tooltip>
          </td>
        </tr>
      </table>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel';
import PageHeader from '@/components/common/PageHeader';
import { mapActions } from 'vuex';

export default {
  name: 'ViewPage',
  components: { MainPanel, PageHeader },

  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
      cityId: null,
      cityPage: null,
    };
  },

  async created() {
    this.cityId = this.$route.params.city_id;
    this.cityPage = await this.fetchWishCity(this.cityId);
  },

  methods: {
    ...mapActions('cities', ['fetchWishCity', 'isPreloadable', 'toggleEventCategories']),
    async changeStatus() {
      await this.isPreloadable({ id: this.cityId, preState: this.cityPage.is_preloadable });
    },
    async onToggleEventCategories() {
      try {
        this.loading = true;
        await this.toggleEventCategories({
          id: this.cityId,
          isEventCategories: this.cityPage.is_event_categories,
        });
      } catch (error) {
        console.error('Помилка при оновленні is_event_categories:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
