<template>
  <a
    class="name-wrapper"
    :href="$router.resolve({name: 'ViewUser', params: {id: user.id}}).href"
    target="_blank"
  >
    <UserAvatar :user="user" />
  </a>
</template>

<script>
import UserAvatar from '@/components/user-avatar/UserAvatar'


export default {
  name: 'UserCard',
  components:{UserAvatar},
  props:{
    user:{
      type:[Object, null],
      required:true,
    },
  },
}

</script>

<style scoped>

</style>
